import React,{useEffect} from "react";
import {  BrowserRouter as Router,Routes, Route,} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import { loadUser } from "./Actions/User";

import Header from './Components/Header';
import Footer from './Components/Footer';

import Home from './screens/Home';
import AboutUs from './screens/AboutUs';
import Infrastructure from './screens/Infrastructure';
import Vision from './screens/Vision';
import Research from './screens/Research';
import Projects from './screens/Projects';
import Team from './screens/Team';
import Download from './screens/Download';
import Contact from './screens/Contact';
import Faq from './screens/Faq';
import Calculator from './screens/Calculator';

import NotFound from './screens/NotFound';
import NotAuthorised from './screens/NotAuthorised';


function App(props) {
  const dispatch = useDispatch();
  const alertOptions = {
    position: positions.TOP_CENTER,
    timeout: 5000,
    transition: transitions.SCALE,
  };
  

  return (
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <Router >
      <div className={App} style={{margin:0,padding:0}}> <Header/>  </div>
      <Routes>
      <Route exact path="/" element={<Home/> }/>
      <Route path="/aboutus" element={<AboutUs/>}/>
      <Route path="/infrastructure" element={<Infrastructure/>}/>
      <Route path="/vision" element={<Vision/>}/>
      <Route path="/research" element={<Research/>}/>
      <Route path="/projects" element={<Projects/>}/>
      <Route path="/team" element={<Team/>}/>
      <Route path="/download" element={<Download/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/faq" element={<Faq/>}/>
      <Route path="/calculator" element={<Calculator/>}/>
      
      <Route path="*" element={<NotFound/>} />
      </Routes>
      
      <Footer/>
    </Router>
    </AlertProvider>
  );
}

export default App;
