import React, { useState } from 'react';

import slab from '../image/icons/slab.png';
import column from '../image/icons/column.png';
import beam from '../image/icons/beam.png';
import footing from '../image/icons/footing.png';

const Calculator = () => {
  const [structure, setStructure] = useState('');
  const [diameter, setDiameter] = useState('');
  const [height, setHeight] = useState('');
  const [thickness, setThickness] = useState('');
  const [width, setWidth] = useState('');
  const [length, setLength] = useState('');
  const [widthUnit, setWidthUnit] = useState('inch');
  const [lengthUnit, setLengthUnit] = useState('inch');
  const [thicknessUnit, setThicknessUnit] = useState('inch');
  const [diameterUnit, setDiameterUnit] = useState('inch');
  const [heightUnit, setHeightUnit] = useState('inch');
  const [baseLength, setBaseLength] = useState('');
  const [baseLengthUnit, setBaseLengthUnit] = useState('inch');
  const [baseHeight, setBaseHeight] = useState('');
  const [baseHeightUnit, setBaseHeightUnit] = useState('inch');
  const [baseWidth, setBaseWidth] = useState('');
  const [baseWidthUnit, setBaseWidthUnit] = useState('inch');
  const [verticalSlantHeight, setVerticalSlantHeight] = useState('');
  const [verticalSlantHeightUnit, setVerticalSlantHeightUnit] = useState('inch');
  const [pillarLength, setPillarLength] = useState('');
  const [pillarLengthUnit, setPillarLengthUnit] = useState('inch');
  const [pillarHeight, setPillarHeight] = useState('');
  const [pillarHeightUnit, setPillarHeightUnit] = useState('inch');
  const [volumeInCubicMeter, setVolumeInCubicMeter] = useState(0);
  const [volumeInCubicFeet, setVolumeInCubicFeet] = useState(0);

  const handleStructureChange = (event) => {
    setStructure(event.target.value);
  };

  const handleThicknessChange = (event) => {
    setThickness(event.target.value);
  };

  const handleWidthChange = (event) => {
    setWidth(event.target.value);
  };

  const handleLengthChange = (event) => {
    setLength(event.target.value);
  };

  const handleDiameterChange = (event) => {
    setDiameter(event.target.value);
  };

  const handleHeightChange = (event) => {
    setHeight(event.target.value);
  };

  const handleWidthUnitChange = (event) => {
    setWidthUnit(event.target.value);
  };

  const handleLengthUnitChange = (event) => {
    setLengthUnit(event.target.value);
  };

  const handleThicknessUnitChange = (event) => {
    setThicknessUnit(event.target.value);
  };

  
  const handleDiameterUnitChange = (event) => {
    setDiameterUnit(event.target.value);
  };

  const handleHeightUnitChange = (event) => {
    setHeightUnit(event.target.value);
  };

  const handleBaseLengthChange = (event) => {
    setBaseLength(event.target.value);
  };

  const handleBaseLengthUnitChange = (event) => {
    setBaseLengthUnit(event.target.value);
  };

  const handleBaseHeightChange = (event) => {
    setBaseHeight(event.target.value);
  };

  const handleBaseHeightUnitChange = (event) => {
    setBaseHeightUnit(event.target.value);
  };

  const handleBaseWidthChange = (event) => {
    setBaseWidth(event.target.value);
  };

  const handleBaseWidthUnitChange = (event) => {
    setBaseWidthUnit(event.target.value);
  };

  const handleVerticalSlantHeightChange = (event) => {
    setVerticalSlantHeight(event.target.value);
  };

  const handleVerticalSlantHeightUnitChange = (event) => {
    setVerticalSlantHeightUnit(event.target.value);
  };

  const handlePillarLengthChange = (event) => {
    setPillarLength(event.target.value);
  };

  const handlePillarLengthUnitChange = (event) => {
    setPillarLengthUnit(event.target.value);
  };

  const handlePillarHeightChange = (event) => {
    setPillarHeight(event.target.value);
  };

  const handlePillarHeightUnitChange = (event) => {
    setPillarHeightUnit(event.target.value);
  };

  const handleCalculate = () => {
    const convertedWidth = convertToMeter(width, widthUnit);
    const convertedLength = convertToMeter(length, lengthUnit);
    const convertedThickness = convertToMeter(thickness, thicknessUnit);

    const volume = convertedWidth * convertedLength * convertedThickness;
    setVolumeInCubicMeter(volume.toFixed(2));
    setVolumeInCubicFeet(convertToCubicFeet(volume).toFixed(2));
  };

  const handleCalculate2 = () => {
    const convertedDiameter = convertToMeter(diameter, diameterUnit);
    const convertedHeight = convertToMeter(height, heightUnit);
  
    const volume = (3.142858 * convertedDiameter * convertedDiameter * convertedHeight) / 4;
    setVolumeInCubicMeter(volume.toFixed(2));
    setVolumeInCubicFeet(convertToCubicFeet(volume).toFixed(2));
  };

  const handleCalculate3 = () => {
    const convertedBaseLength = convertToMeter(baseLength, baseLengthUnit);
    const convertedBaseHeight = convertToMeter(baseHeight, baseHeightUnit);
    const convertedBaseWidth = convertToMeter(baseWidth, baseWidthUnit);
    const convertedVerticalSlantHeight = convertToMeter(verticalSlantHeight, verticalSlantHeightUnit);
    const convertedPillarLength = convertToMeter(pillarLength, pillarLengthUnit);
    const convertedPillarHeight = convertToMeter(pillarHeight, pillarHeightUnit);
  
    const baseArea = ((convertedBaseWidth + convertedVerticalSlantHeight) / 2) * convertedBaseLength;
  const footingVolume = baseArea * convertedBaseHeight;
  const pillarVolume = convertedPillarLength * convertedPillarHeight;
  const totalVolume = (footingVolume + pillarVolume) /29.5249 ;

  
    setVolumeInCubicMeter(totalVolume.toFixed(2));
    setVolumeInCubicFeet(convertToCubicFeet(totalVolume).toFixed(2));
  };

  const convertToMeter = (value, unit) => {
    if (unit === 'inch') {
      return value * 0.0254; // Conversion factor for inch to meter
    } else if (unit === 'feet') {
      return value * 0.3048; // Conversion factor for feet to meter
    } else {
      return value; // Already in meters
    }
  };

  const convertToCubicFeet = (volume) => {
    return volume * 35.3147; // Conversion factor for cubic meter to cubic feet
  };

  return (
    <div style={{margin:0,padding:0,height:500,overflow:'scroll'}}>
    <div className="row" style={{margin:0,paddingLeft:20,paddingRight:20}}>
    <h1 style={{margin:0,padding:0}}>RMC Volume Calculator</h1>
    <label style={{margin:0,padding:0}}>Select Structure:</label>
    <div className="col-lg-6 sm-12" style={{margin:0,padding:0}}>
    <select value={structure} onChange={handleStructureChange} style={{width:'100%',borderRadius:5,padding:5}}>
      <option value="">-- Select Structure --</option>
      <option value="slab">Slab</option>
      <option value="footing">Footing</option>
      <option value="beam">Beam</option>
      <option value="column">Column</option>
    </select>
    </div>
    <div className="col-lg-6 sm-12" style={{margin:0,padding:0}}></div>

    {structure === 'slab' && (
      <>
        <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}>
          <div className="row" style={{margin:0,padding:0}}>
            <img alt="slab" src={slab} style={{width:'95%'}}/>
          </div>

          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
              <label>Thickness:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={thickness} onChange={handleThicknessChange} style={{width:'100%'}}/>
            </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={thicknessUnit} onChange={handleThicknessUnitChange} style={{width:'100%'}}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select>
            </div>
          </div>

          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
              <label>Width:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={width} onChange={handleWidthChange}  style={{width:'100%'}}/>
            </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={widthUnit} onChange={handleWidthUnitChange}  style={{width:'100%'}}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select>
            </div>
          </div>

          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
               <label>Length:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={length} onChange={handleLengthChange} style={{width:'100%'}}/>
              </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={lengthUnit} onChange={handleLengthUnitChange} style={{width:'100%'}}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select> 
            </div>
          </div>

          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-12 sm-12" style={{margin:0,padding:2}}>
              <button onClick={handleCalculate} style={{width:'100%'}}>Calculate</button>
            </div>
          </div>

        </div>
        <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}></div>
        <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}>
          <h2>Volume:</h2>
          <p>Cubic Meter: {volumeInCubicMeter}</p>
          <p>Cubic Feet: {volumeInCubicFeet}</p>
        </div>
      </>
    )}

    {structure === 'footing' && (
      <>
        <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}>
          <div className="row" style={{margin:0,padding:0}}>
          <img alt="footing" src={footing} style={{width:'95%'}}/>
        </div>

        <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
              <label>Base Length:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={baseLength} onChange={handleBaseLengthChange} />
            </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={baseLengthUnit} onChange={handleBaseLengthUnitChange}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select>
            </div>
          </div>

          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
              <label>Base Height:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={baseHeight} onChange={handleBaseHeightChange} />
            </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={baseHeightUnit} onChange={handleBaseHeightUnitChange}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select>
            </div>
          </div>

          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
              <label>Base Width:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={baseWidth} onChange={handleBaseWidthChange} />
            </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={baseWidthUnit} onChange={handleBaseWidthUnitChange}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select>
            </div>
          </div>

          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
              <label>Vertical Slant Height:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={verticalSlantHeight} onChange={handleVerticalSlantHeightChange} />
            </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={verticalSlantHeightUnit} onChange={handleVerticalSlantHeightUnitChange}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select>
            </div>
          </div>

          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
              <label>Pillar Length:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={pillarLength} onChange={handlePillarLengthChange} />
            </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={pillarLengthUnit} onChange={handlePillarLengthUnitChange}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select>
            </div>
          </div>

          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
              <label>Pillar Height:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={pillarHeight} onChange={handlePillarHeightChange} />
            </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={pillarHeightUnit} onChange={handlePillarHeightUnitChange}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select>
            </div>
          </div>

    
      <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-12 sm-12" style={{margin:0,padding:2}}>
              <button onClick={handleCalculate} style={{width:'100%'}}>Calculate</button>
            </div>
          </div>

        </div>
        <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}></div>
        <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}>
          <h2>Volume:</h2>
          <p>Cubic Meter: {volumeInCubicMeter}</p>
          <p>Cubic Feet: {volumeInCubicFeet}</p>
        </div>
      </>
    )}

    {structure === 'beam' && (
      <>
      <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}>
        <div className="row" style={{margin:0,padding:0}}>
          <img alt="beam" src={beam} style={{width:'95%'}}/>
        </div>

        <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
              <label>Thickness:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={thickness} onChange={handleThicknessChange} style={{width:'100%'}}/>
            </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={thicknessUnit} onChange={handleThicknessUnitChange} style={{width:'100%'}}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select>
            </div>
          </div>

          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
              <label>Width:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={width} onChange={handleWidthChange}  style={{width:'100%'}}/>
            </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={widthUnit} onChange={handleWidthUnitChange}  style={{width:'100%'}}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select>
            </div>
          </div>

          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
               <label>Length:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={length} onChange={handleLengthChange} style={{width:'100%'}}/>
              </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={lengthUnit} onChange={handleLengthUnitChange} style={{width:'100%'}}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select> 
            </div>
          </div>

          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-12 sm-12" style={{margin:0,padding:2}}>
              <button onClick={handleCalculate} style={{width:'100%'}}>Calculate</button>
            </div>
          </div>

        </div>
        <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}></div>
        <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}>
          <h2>Volume:</h2>
          <p>Cubic Meter: {volumeInCubicMeter}</p>
          <p>Cubic Feet: {volumeInCubicFeet}</p>
        </div>
      </>
    )}

    {structure === 'column' && (
      <>
      <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}>
        <div className="row" style={{margin:0,padding:0}}>
        <img alt="column" src={column} style={{width:'95%'}}/>
        </div>

        <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
            <label>Diameter:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={diameter} onChange={handleDiameterChange} style={{width:'100%'}}/>
            </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={diameterUnit} onChange={handleDiameterUnitChange} style={{width:'100%'}}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select>
            </div>
          </div>
            
          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-4 sm-12" style={{margin:0,padding:2}}>
              <label>Height:</label>
            </div>
            <div className="col-lg-6 sm-12" style={{margin:0,padding:2}}>
              <input type="number" value={height} onChange={handleHeightChange} style={{width:'100%'}}/>
            </div>
            <div className="col-lg-2 sm-12" style={{margin:0,padding:2}}>
              <select value={heightUnit} onChange={handleHeightUnitChange} style={{width:'100%'}}>
                <option value="inch">Inch</option>
                <option value="feet">Feet</option>
                <option value="metre">Metre</option>
              </select>
            </div>
          </div>

          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-12 sm-12" style={{margin:0,padding:2}}>
              <button onClick={handleCalculate2} style={{width:'100%'}}>Calculate</button>
            </div>
          </div>

        </div>
        <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}></div>
        <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}>
          <h2>Volume:</h2>
          <p>Cubic Meter: {volumeInCubicMeter}</p>
          <p>Cubic Feet: {volumeInCubicFeet}</p>
        </div>
  </>
    )}
  </div>
  </div>
);
};

export default Calculator;