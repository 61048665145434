import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>404 Error: Page Not Found</h1>
      <p>The requested URL was not found on this server.</p>
    </div>
  );
};

export default NotFound;