import React from 'react'

import image from '../image/missionimage.png'
const Vision = () => {
  return (
    <div className="row" style={{margin:0,padding:0,height:'440px'}}>
      <div className="col-md-5 sm-12">
          <p style={{fontSize:'40px',color:'#075444',lineHeight:'1'}}>Vision and Goals</p>
          <p style={{fontSize:'16px', textAlign:'justify', color:'#B72221', lineHeight:'1'}}><b>Our Vision.</b></p>
         <p style={{fontSize:'12px', textAlign:'justify', color:'black'}}>To be the most trusted provider of concrete solutions in the North Eastern India</p>

         <p style={{fontSize:'16px', textAlign:'justify', color:'#393185', lineHeight:'1'}}><b>Quality Policy</b></p>
         <p style={{fontSize:'12px', textAlign:'justify', color:'black'}}>
         *   We are committed to achieve customer delight by manufacturing & supplying best consistent quality concrete<br></br>
         *   To offer total concrete solution to our valued customers<br></br>
         *   To evaluate & continous improvement of quality management system through periodic assessments & review system<br></br>
         *   To deliver product on time to achieve customer satisfaction<br></br>
         *   To benchmark with best industrial practises and use innovative techniques & upgrade our product with the latest indian & international standard<br></br>
         *   To adopt fair transparent & ethical practises in our Business</p><br></br>
      </div>
      <div className="col-md-1 sm-12"></div>
      <div className="col-md-5 sm-12" style={{marginTop:30}}>
        <img src={image} alt=""style={{width:'100%'}}/>
      </div>
      <div className="col-md-1 sm-12"></div>
    </div>
  )
}

export default Vision