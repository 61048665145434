import React from 'react'
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

import video from '../image/icons/flex1.mp4'
import nermctruck from '../image/nermctruck.jpg'
import image from '../image/missionimage.png'
import infrastructure from '../image/infrastructure.png'
import pallab from '../image/pallab.png'
import ranjit from '../image/ranjit.png'
import rikan from '../image/rikon.png'
import anup from '../image/anup.png'

const Home = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed : 1000,
    cssEase: "linear"
  };
  return (
    <div className="row" style={{margin:0,padding:0}}>
       <Carousel prevLabel="" nextLabel="" style={{ margin: 0, padding: 0 }}>
        <Carousel.Item>
          <video controls={false} autoPlay src={video} type="video/mp4" style={{ width: '100%', height: '100%' }} />
        </Carousel.Item>
      </Carousel>
      <div className="col-lg-8 sm-12" style={{margin:0,padding:20,}}>
        <div className="row" style={{margin:0,padding:0, borderRadius:10, border: '1px solid #969696'}}>
          <div className="col-lg-7 sm-12" style={{margin:0,padding:20}}>
            <p style={{fontSize:'24px',color:'#EF7F1A',lineHeight:'.7'}}>What is RMC ?</p>
            <p style={{fontSize:'12px', textAlign:'justify', color:'#5B5B5B'}}>RMC is ready to use concrete that is manufactured at a factory or batching plant, according to a set recipe (or as per the client specification) and then derived to the worksites by truck mounted mixers(transit mixer)
             RMC is often preferred over on-site concrete mixing because of the precision and quality of the mix, reduction of onsite confusion and expenses and a high versatility in usage due to various </p>
             <Link className="nav-link" to="/aboutus"><p style={{fontSize:'12px', textAlign:'left', color:'#5CA595'}}>See more...</p></Link>
          </div>
          <div className="col-lg-5 sm-12" style={{margin:0,padding:0}}>
            <img src={nermctruck} alt=""style={{width:'95%'}}/>
          </div>
        </div>
      </div>
      <div className="col-lg-4 sm-12" style={{margin:0,padding:20,marginTop:30}}>
            <p style={{fontSize:'28px',color:'#B72221',lineHeight:'.7'}}>About Us</p>
            <p style={{fontSize:'16px', textAlign:'justify', color:'#393185', lineHeight:'.7'}}><b>Smart Choice for Smart Constructions</b></p>
            <p style={{fontSize:'12px', textAlign:'justify', color:'#5B5B5B'}}>NE RMC is a leading manufacturer and supplier of readymix concrete (RMC) catering the concrete requiremnts in and around Guwahati. We are synonymous to trust and quality and we believe to deliver products to build a smart, beautiful and progressive world around you.</p>
            <Link className="nav-link" to="/aboutus"><p style={{fontSize:'12px', textAlign:'left', color:'#5CA595'}}>See more...</p></Link>
      </div>
      <div className="col-lg-7 sm-12" style={{margin:0,padding:20}}>
          <div className="row" style={{margin:0,padding:0}}>
            <div className="col-lg-7 sm-12" style={{margin:0,padding:0}}>
              <p style={{fontSize:'28px',color:'#393185',lineHeight:'1'}}>Infrastructure</p>
              <p style={{fontSize:'12px', textAlign:'justify', color:'red', lineHeight:'.7'}}><b>Essential for every Infrastructure...</b></p>
              <p style={{fontSize:'12px', textAlign:'justify', color:'black'}}>NE RMC Readymix is proud of it Long acquired knowldege base by its team having expertise at Sales , Service, Operation and Quality Control had served at RMC organizations in different capacities for many years. We endeavor to deliver every customer needs with the best value proposition thus making our product essential for every infrastructure</p> 
          <p style={{fontSize:'12px', textAlign:'justify', color:'red', lineHeight:'.7'}}><b>Our History...</b></p>
          <p style={{fontSize:'12px', textAlign:'justify', color:'black'}}>Established in March 2017 , the company employs more than 100+ people operating in various fields respectively and produces more than 1 million <br></br>
          <Link className="nav-link" to="/infrastructure"><p style={{fontSize:'12px', textAlign:'left', color:'#5CA595'}}>See more...</p></Link></p>
              
            </div>
            <div className="col-lg-5 sm-12" style={{margin:0,padding:20}}>
                <img src={infrastructure} alt=""style={{width:'100%'}}/>
            </div>
          </div>
      </div>
      <div className="col-lg-5 sm-12" style={{margin:0,padding:20,marginTop:20}}>
      <img src={image} alt=""style={{width:'100%'}}/>
      </div>
      <div className="col-lg-4 sm-12" style={{margin:0,padding:20}}>
              <p style={{fontSize:'28px',color:'#B72221',lineHeight:'1'}}> Our Team</p>
              <p style={{fontSize:'16px', textAlign:'justify', color:'#075444', lineHeight:'.7'}}><b>Talent and Knowledge Create Wonders..</b></p>
              <p style={{fontSize:'12px', textAlign:'justify', color:'black'}}>Not only do we boast having some of the region’s most experience concrete experts, but our 50+ member team forms one of the industry’s most talented contributing to the success of the<br></br>
              </p>
              <Link className="nav-link" to="/team"><p style={{fontSize:'12px', textAlign:'left', color:'#5CA595'}}>See more...</p></Link>
      </div>
      <div className="col-lg-8 sm-12" style={{margin:0,padding:20}}>
        <div className="row" style={{margin:0,padding:0}}>
            <Slider {...settings}>
                <div className="col-4" style={{borderRadius:'200px 0px 0px 200px',border: '3px solid #969696',marginLeft:20}}>
                <div className="row" style={{margin:0,padding:0}}>
                  <div className="col-6" style={{marginLeft:0,padding:10}}>
                    <img src={pallab} alt=""style={{width:'100%',borderRadius:'50%'}}/>
                  </div>
                  <div className="col-6" style={{margin:0,padding:10,}}>
                    <p style={{fontSize:'10px',lineHeight:1.2}}>
                    A Master Degree in Arts (Geography) , <b>PALLAB KUMAR RABHA</b> has expertise of
                    of more than 15+ years in selling of RMC products , handled different position of marketing
                    and sales, in three different RMC <br></br>
                    <Link className="nav-link" to="/team"><p style={{fontSize:'12px', textAlign:'left', color:'#5CA595'}}>See more...</p></Link>
                    </p>
                  </div>   
                </div>        
                </div>

                <div className="col-4" style={{borderRadius:'200px 0px 0px 200px',border: '3px solid #969696',marginLeft:20}}>
                <div className="row" style={{margin:0,padding:0}}>
                  <div className="col-6" style={{marginLeft:0,padding:10}}>
                    <img src={ranjit} alt=""style={{width:'100%',borderRadius:'50%'}}/>
                  </div>
                  <div className="col-6" style={{margin:0,padding:10}}>
                  <p style={{fontSize:'10px',lineHeight:1.2}}>
                  Mr. RANJIT DAS, a B.A. in Economics, having expertise of more than 15+ years in selling of RMC products, handled different position of marketing and sales, in four different RMC organizations.<br></br>
                  <Link className="nav-link" to="/team"><p style={{fontSize:'12px', textAlign:'left', color:'#5CA595'}}>See more...</p></Link>
                  </p>
                  </div>   
                </div>        
                </div>

                <div className="col-4" style={{borderRadius:'200px 0px 0px 200px',border: '3px solid #969696',marginLeft:20}}>
                <div className="row" style={{margin:0,padding:0}}>
                  <div className="col-6" style={{marginLeft:0,padding:10}}>
                    <img src={anup} alt=""style={{width:'100%',borderRadius:'50%'}}/>
                  </div>
                  <div className="col-6" style={{margin:0,padding:10}}>
                  <p style={{fontSize:'10px',lineHeight:1.2,}}>
                    Mr. ANUP KALITA, is a B.Tech, CIVIL Engineeer by profession, having expertise of more than 15+ years in the field of Site Engineer, Technical Service, QC, Sales, People management, serving in construction <br></br>
                    <Link className="nav-link" to="/team"><p style={{fontSize:'12px', textAlign:'left', color:'#5CA595'}}>See more...</p></Link>
                    </p>
                  </div>   
                </div>        
                </div>

                <div className="col-4" style={{borderRadius:'200px 0px 0px 200px',border: '3px solid #969696',marginLeft:20}}>
                <div className="row" style={{margin:0,padding:0}}>
                  <div className="col-6" style={{marginLeft:0,padding:10}}>
                    <img src={rikan} alt=""style={{width:'100%',borderRadius:'50%'}}/>
                  </div>
                  <div className="col-6" style={{margin:0,padding:10}}>
                    <p style={{fontSize:'10px',lineHeight:1.2}}>
                    Mr. RIKAN DAS is a Diploma in Mechanical Engineering,having expertise of more than 15+ years in the field of site engg maintenance, handling different
                    equipments, people management <br></br>
                    <Link className="nav-link" to="/team"><p style={{fontSize:'12px', textAlign:'left', color:'#5CA595'}}>See more...</p></Link>
                    </p>
                  </div>   
                </div>        
                </div>

            </Slider>
        </div>
      </div>
    </div>
  )
}

export default Home