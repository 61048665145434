import React from 'react'

import team from '../image/team.png'
import pallab from '../image/pallab.png'
import ranjit from '../image/ranjit.png'
import rikan from '../image/rikon.png'
import anup from '../image/anup.png'

const Team = () => {
  return (
    <div className="row" style={{margin:0,padding:0}}>
      <div className="col-md-9 sm-12">
        <div className="row" style={{margin:0,padding:0}}>
          <div className="col-md-12 sm-12" style={{margin:0,padding:0}}>
              <p style={{fontSize:'40px',color:'#B72221',lineHeight:'1'}}>Our Team and Partners</p>
              <p style={{fontSize:'18px', textAlign:'justify', color:'#075444', lineHeight:'1'}}><b>Talent and Knowledge Create Wonders..</b></p>
              <p style={{fontSize:'12px', textAlign:'justify', color:'black'}}>Not only do we boast having some of the region’s most experience concrete experts, but our 50+ member team forms one of the industry’s most talented contributing to the success of the Compviewany by delivering the highest standnards of quality and service.<br></br>
              We cultivate a rich learning environment for employees through a vigorous selection, training and professional development system. Everyone in the compviewany is “concrete” conversant after going through a series of special in-house concrete technology courses, instilling more knowledge and passion for this wonderful product.</p>
              <p style={{fontSize:'18px', textAlign:'justify', color:'#393185', lineHeight:'1'}}><b>Our Directors</b></p>
          </div>
          
          <div className="col-md-12 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>

              <div className="compview" style={{width:'400px',borderRadius:'200px 0px 0px 200px',border: '1px solid #969696'}}>
                <div className="row" style={{margin:0,padding:10}}>
                  <div className="col-6" style={{marginLeft:0,padding:0,marginLeft:-10}}>
                    <img src={pallab} alt=""style={{width:'100%',borderRadius:'50%'}}/>
                  </div>
                  <div className="col-6" style={{margin:0,padding:0,paddingLeft:10}}>
                    <p style={{fontSize:'10px',lineHeight:1.2}}>
                    A Master Degree in Arts (Geography) , PALLAB KUMAR RABHA has expertise of
                    nearly nine years in selling of RMC products , handled different position of marketing
                    and sales, in three different RMC organizations. <br></br><br></br>

                    <b style={{color:'#393185'}}>Previous Experience : </b><br></br>
                    Floors & Walls<br></br>
                    Lafarge Aggregrates & Concrete India Ltd.<br></br>
                    Larsen & Tourbo Ltd.
                    </p>
                  </div>
                </div>            
              </div>

              <div className="compview" style={{marginLeft:20,width:'400px',borderRadius:'200px 0px 0px 200px',border: '1px solid #969696'}}>
                <div className="row" style={{margin:0,padding:10}}>
                  <div className="col-6" style={{marginLeft:0,padding:0,marginLeft:-10}}>
                    <img src={ranjit} alt=""style={{width:'100%',borderRadius:'50%'}}/>
                  </div>
                  <div className="col-6" style={{margin:0,padding:0,paddingLeft:10}}>
                  <p style={{fontSize:'10px',lineHeight:1.2}}>
                  Mr. RANJIT DAS, is a B.A. in Economics, having expertise of nearly 9 years in selling of RMC products, handled different position of marketing and sales, in four different RMC organizations.<br></br><br></br>
                  <b style={{color:'#393185'}}>Previous Experience : </b><br></br>
                  Floors & Walls<br></br>
                  Green Concretex<br></br>
                  MBL Infrastructures Ltd.<br></br>
                  Larsen & Turbo
              </p>
                  </div>
                </div>            
              </div>

            </div>
            <div className="row" style={{margin:0,padding:0,marginTop:20,marginBottom:20}}>

              <div className="compview" style={{width:'400px',borderRadius:'200px 0px 0px 200px',border: '1px solid #969696'}}>
                <div className="row" style={{margin:0,padding:10}}>
                  <div className="col-6" style={{marginLeft:0,padding:0,marginLeft:-10}}>
                    <img src={anup} alt=""style={{width:'100%',borderRadius:'50%'}}/>
                  </div>
                  <div className="col-6" style={{margin:0,padding:0,paddingLeft:10}}>
                    <p style={{fontSize:'10px',lineHeight:1.2,textAlign:'justify'}}>
                    Mr. ANUP KALITA, is a B.Tech, CIVIL Engineeer by profession, having expertise of nearly 10 years in the field of Site Engineer, Technical Service, QC, Sales, People management, serving in construction as well as building materials in different levels .He will be actively looking into the overall business of the firm<br></br>
                    <b style={{color:'#393185'}}>Previous Experience : </b><br></br>
                    RMC Readymix India <br></br>
                    ULTRATECH CEMENT LTD.<br></br>
                    ITD CEM INDIA PVT LTD.<br></br>
                    </p>
                  </div>
                </div>            
              </div>

              <div className="compview" style={{marginLeft:20,width:'400px',borderRadius:'200px 0px 0px 200px',border: '1px solid #969696'}}>
                <div className="row" style={{margin:0,padding:10}}>
                  <div className="col-6" style={{marginLeft:0,padding:0,marginLeft:-10}}>
                    <img src={rikan} alt=""style={{width:'100%',borderRadius:'50%'}}/>
                  </div>
                  <div className="col-6" style={{margin:0,padding:0,paddingLeft:10}}>
                    <p style={{fontSize:'10px',lineHeight:1.2}}>
                    Mr. RIKAN DAS is a Diploma in Mechanical Engineering,having expertise of nearly 8 years in the field of site engg maintenance, handling different
                    equipments, people management, serving in construction industry <br></br><br></br>
                    <b style={{color:'#393185'}}>Previous Experience : </b><br></br>
                    RMC Readymix India<br></br>
                    Jaiprakash Associates Limited<br></br>
                    Simplex Infrastructuires Limited
                    </p>
                  </div>
                </div>            
              </div>

              </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 sm-12" style={{marginTop:10}}>
        <img src={team} alt=""style={{width:'100%'}}/>
      </div>
      <div className="row" style={{margin:0,padding:10}}>
        <div className="mobileview" style={{width:'400px',borderRadius:'200px 0px 0px 200px',border: '1px solid #969696'}}>
          <div className="row" style={{margin:0,padding:10}}>
            <div className="col-6" style={{marginLeft:0,padding:0,marginLeft:-10}}>
              <img src={pallab} alt=""style={{width:'100%',borderRadius:'50%'}}/>
            </div>
            <div className="col-6" style={{margin:0,padding:0,paddingLeft:10}}>
              <p style={{fontSize:'9px',lineHeight:1.2}}>
              A Master Degree in Arts (Geography) , PALLAB KUMAR RABHA has expertise of
              of more than 15+ years in selling of RMC products , handled different position of marketing
              and sales, in three different RMC organizations. <br></br><br></br>

              <b style={{color:'#393185'}}>Previous Experience : </b><br></br>
              Floors & Walls<br></br>
              Lafarge Aggregrates & Concrete India Ltd.<br></br>
              Larsen & Tourbo Ltd.
              </p>
            </div>
          </div>            
        </div>
      </div>
      <div className="row" style={{margin:0,padding:10}}>
        <div className="mobileview" style={{width:'400px',borderRadius:'200px 0px 0px 200px',border: '1px solid #969696'}}>
          <div className="row" style={{margin:0,padding:10}}>
              <div className="col-6" style={{marginLeft:0,padding:0,marginLeft:-10}}>
                  <img src={ranjit} alt=""style={{width:'100%',borderRadius:'50%'}}/>
              </div>
              <div className="col-6" style={{margin:0,padding:0,paddingLeft:10}}>
                  <p style={{fontSize:'9px',lineHeight:1.2}}>
                  Mr. RANJIT DAS, is a B.A. in Economics, having expertise of more than 15+ years in selling of RMC products, handled different position of marketing and sales, in four different RMC organizations.<br></br><br></br>
                  <b style={{color:'#393185'}}>Previous Experience : </b><br></br>
                  Floors & Walls<br></br>
                  Green Concretex<br></br>
                  MBL Infrastructures Ltd.<br></br>
                  Larsen & Turbo
                </p>
              </div>
            </div>                  
        </div>
      </div>
      <div className="row" style={{margin:0,padding:10}}>
        <div className="mobileview" style={{width:'400px',borderRadius:'200px 0px 0px 200px',border: '1px solid #969696'}}>
          <div className="row" style={{margin:0,padding:10}}>
              <div className="col-6" style={{marginLeft:0,padding:0,marginLeft:-10}}>
                <img src={anup} alt=""style={{width:'100%',borderRadius:'50%'}}/>
              </div>
              <div className="col-6" style={{margin:0,padding:0,paddingLeft:10}}>
                  <p style={{fontSize:'9px',lineHeight:1.2,textAlign:'justify'}}>
                  Mr. ANUP KALITA, is a B.Tech, CIVIL Engineeer by profession, having expertise of more than 15+ years in the field of Site Engineer, Technical Service, QC, Sales, People management, serving in construction as well as building materials in different levels .He will be actively looking into the overall business of the firm<br></br>
                  <b style={{color:'#393185'}}>Previous Experience : </b><br></br>
                  RMC Readymix India <br></br>
                  ULTRATECH CEMENT LTD.<br></br>
                  ITD CEM INDIA PVT LTD.<br></br>
                  </p>
              </div>
          </div>          
        </div>
      </div>
      <div className="row" style={{margin:0,padding:10}}>
        <div className="mobileview" style={{width:'400px',borderRadius:'200px 0px 0px 200px',border: '1px solid #969696'}}>
          <div className="row" style={{margin:0,padding:10}}>
            <div className="col-6" style={{marginLeft:0,padding:0,marginLeft:-10}}>
              <img src={rikan} alt=""style={{width:'100%',borderRadius:'50%'}}/>
            </div>
            <div className="col-6" style={{margin:0,padding:0,paddingLeft:10}}>
              <p style={{fontSize:'9px',lineHeight:1.2}}>
              Mr. RIKAN DAS is a Diploma in Mechanical Engineering,having expertise of nearly 8 years in the field of site engg maintenance, handling different
                    equipments, people management, serving in construction industry <br></br><br></br>
                    <b style={{color:'#393185'}}>Previous Experience : </b><br></br>
                    RMC Readymix India<br></br>
                    Jaiprakash Associates Limited<br></br>
                    Simplex Infrastructuires Limited
              </p>
            </div>
          </div>            
        </div>
      </div>


    </div>
    
  )
}

export default Team