import React from 'react'

import research from '../image/research.png'

const Research = () => {
  return (
    <div className="row" style={{margin:0,padding:0,height:'440px'}}>
    <div className="col-md-3 sm-12" style={{margin:0,padding:20}}>
      <img src={research} alt=""style={{width:'100%'}}/>  
    </div>
    <div className="col-md-1 sm-12"></div>
    <div className="col-md-7 sm-12" style={{margin:0,padding:20}}>
          <p style={{fontSize:'40px',color:'#B72221',lineHeight:'1'}}>Research and Product Development</p>
          <p style={{fontSize:'16px', textAlign:'justify', color:'#075444', lineHeight:'.7'}}><b>Fast and Effective Solutions</b></p>
          <p style={{fontSize:'12px', textAlign:'justify', color:'black'}}>We urge to continously develop our product to meet the changing demands of the industry never ceases. Over the years, we have added new porducts to our portfolio of propriety concrete mixes and other masonry products.</p>
          
          <p style={{fontSize:'16px', textAlign:'justify', color:'#393185', lineHeight:'.7'}}><b>Knowledge..</b></p>
          <p style={{fontSize:'12px', textAlign:'justify', color:'black'}}>By working closely with local and professional socities, participating in technical events and remaing aware of the latest developments in concrete industry worldwide, our research and development team works continously on studying and developing new products for the use in the local market.<br></br>
          They are also actively involved with consultants and contractors to develop new concrete mixes for key projects and introduce new technical solutions for major site challenges.</p><br></br>
    </div>
    </div>
  )
}

export default Research