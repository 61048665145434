import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';

const Faq = () => {
  const [visibleDiv, setVisibleDiv] = useState(null);

  const handleClick = (divNumber) => {
    setVisibleDiv(divNumber);
  };

  const renderDiv = (divNumber, content) => {
    return (
      <div style={{ display: visibleDiv === divNumber ? 'block' : 'none' }}>
        {content}
      </div>
    );
  };
  return (
    <Row className="" style={{margin:0,padding:10}}>

      <div className="col-md-4 sm-12" style={{height:480, overflowX:'scroll', margin:0,padding:10, marginTop:-10}}>
        <p style={{fontSize:38, lineHeight:.7}}>FAQ<br></br>
        <b style={{fontSize:16}}>FREQUENTLY ASKED QUESTIONS</b></p>

        <button onClick={() => handleClick(1)}  style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        What are the advantages of using ready-mixed concrete over site-mixed concrete? How much is the difference between the cost of ready-mixed and site-mixed concrete?
        </p></button>

        <button onClick={() => handleClick(2)}  style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        Is ready mixed concrete a well established technology? How long this technology is being used in practice in India?
        </p></button>

        <button onClick={() => handleClick(3)} style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        What equation should i use to find out how my concrete i need? How can we arrive at the exact quantity of concrete required at our site?
        </p></button>

        <button onClick={() => handleClick(4)} style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        Can we base the measurements of concrete on the simple L (length) x B (breadth) x H (height) multiplication?
        </p></button>

        <button onClick={() => handleClick(5)}  style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        How can a buyer of ready-mixed concrete be assured of the quantity supplied
        </p></button>

        <button onClick={() => handleClick(6)} style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        How do you guarantee the quality of your concrete delivered at site?
        </p></button>
        
        <button onClick={() => handleClick(7)}  style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        Why is it essential to employ trained personnel for sampling and preparation of test cubes?
         </p></button>

        <button onClick={() => handleClick(8)}  style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
       How long does readymix concrete need to set?
       </p></button>
        
        <button onClick={() => handleClick(9)}  style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        How can you assure us about the long-term durability of our structures with your concrete
        </p></button>

        <button onClick={() => handleClick(10)}  style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        Are there quality standards for ready mix concrete?
        </p></button>

        <button onClick={() => handleClick(11)}  style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        Can we arrange for transportation of concrete from your plant to our site in our trasnit mixer/dumper?
        </p></button>

        <button onClick={() => handleClick(12)}  style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        Do we have to start curing earlier for ready-mixed concrete after placement
        </p></button>

        <button onClick={() => handleClick(13)}  style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        What are the compliances criteria for ready-mix concrete?
        </p></button>

        <button onClick={() => handleClick(14)}  style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        How the use of fly ash enhances quality of concrete? How to ensure good quality fly ash?
        </p></button>

        <button onClick={() => handleClick(15)}  style={{border:'none',textAlign:'Left'}}><p style={{fontSize:12}}>
        How is ready mix eco-friendly?
        </p></button>

      </div><br></br>
      <div className="col-md-4 sm-12" style={{height:480,margin:0,padding:10,marginLeft:20,border: '1px solid #969696', overflowX:'scroll'}}>
      {renderDiv(1, (
      <div>
        <p style={{fontSize:12}}> Using ready-mixed concrete (RMC) offers several advantages over site-mixed concrete, including:<br></br>
1. Consistency and Quality: RMC is manufactured in a controlled environment, ensuring consistent proportions of ingredients and better quality control. This results in a more uniform and reliable product, reducing the risk of variations in strength and durability.<br></br><br></br>
2. Time and Labor Efficiency: With RMC, you eliminate the need for on-site concrete mixing, which can be time-consuming and labor-intensive. RMC is delivered directly to the construction site, saving time and effort in material handling and mixing. This allows for faster construction progress and increased productivity.<br></br><br></br>
3. Reduced Storage and Equipment Requirements: Site-mixed concrete requires space for storing raw materials, such as cement, aggregates, and water, along with equipment like mixers. By using RMC, you eliminate the need for on-site storage and mixing equipment, freeing up space and reducing logistical complexities.<br></br><br></br>
4. Quality Control and Testing: RMC suppliers follow strict quality control measures and conduct regular testing to ensure compliance with industry standards. This provides assurance that the concrete meets specified strength and durability requirements.<br></br><br></br>
5. Environmental Benefits: RMC production often incorporates sustainable practices, such as optimizing the use of raw materials, recycling industrial by-products, and minimizing waste. It can also help reduce noise, dust, and air pollution on-site, improving the overall environmental impact of the construction project.<br></br><br></br>
The cost difference between ready-mixed and site-mixed concrete can vary depending on factors such as location, project size, and local market conditions. In general, RMC tends to be slightly more expensive than site-mixed concrete due to the additional costs involved in production, transportation, and quality control. However, it is essential to consider the overall cost-effectiveness, taking into account the time, labor, and potential quality benefits offered by RMC. It is recommended to obtain quotes from local suppliers to determine the specific cost difference in your area.</p>
      </div>))}

      {renderDiv(2, (
      <div>
        <p style={{fontSize:12}}>
        Yes, ready-mixed concrete is a well-established technology. It was first patented in Germany, way back in 1903. By 1950s, the use of ready mixed concrete picked up in most of the advanced countries in Europe and America. Compared to the advanced countries, India was a late starter in ready-mixed concrete. RMC technology came to India in 1996. Technologically speaking, the production process of ready mixed concrete has been continuously been upgraded since 1996, leading to improvement in product quality and uniformity. Today's plants in India are highly automated and consist of state-of-the-art machinery with computerized controls over the entire process of production.
        </p>
      </div>))}

      {renderDiv(3, (
      <div>
        <p style={{fontSize:12}}>
        Calculate concrete:<br></br>
* Determine how thick you want the concrete<br></br>
* Measure the length and width that you’d like to cover<br></br>
* Multiply the length by the width to determine square footage<br></br>
* Convert the thickness from inches to feet<br></br>
* Multiply the thickness in feet by the square footage to determine cubic feet<br></br>
* Convert cubic feet to cubic yards by multiplying by .037<br></br><br></br>
Generally your architect/engineers need to figure out the grade-wise concrete requirements based on plan of the structure and communicate the same to our plant. They are the right people and are in a better position to decide the quantities required. Concrete is generally specified in terms of the 28-day compressive strength and workability (usually slump) at placement point. These two are basic minimum requirements for us to design concrete mixes for you.
        </p>
      </div>))}

      {renderDiv(4, (
      <div>
        <p style={{fontSize:12}}>
        All over the world, the basis of purchase and hence measurement of ready-mixed concrete is the volume of freshly mixed concrete. The Indian Standard on ready-mixed concrete, IS 4926:2003 states, “All concrete will be supplied and invoiced in terms of cubic meters (full or part) of compacted fresh concrete”. If the measurement is based on the simple L x D x H multiplication, it would be unfair to the RMC producer as it would not include the wastage and spillage of concrete, miscalculations in form volume, deflection or distortions of forms, settlement of wet mixes, over excavation, loss of entrained air, etc. In most of these cases, the responsibility does not lie with the RMC producer. Improper excavation is a common site problem. Deflection or distortion of forms is also quite common. It is observed that if the centre of 150-mm thick formwork of a slab gets deflected by 5 mm, an increased volume of 3 % would be required at the centre, decreasing towards the edges. Therefore ready-mixed concrete should only be measured based on the actual volume of fresh concrete supplied through transit mixers.
        </p>
      </div>))}

      {renderDiv(5, (
      <div>
      <p style={{fontSize:12}}>
      The buyer can ensure the quantity supplied by checking the weight of the vehicle in a weighbridge before unloading and after unloading in order to obtain a net weight. Another alternative is to cross check the dimensions of the slab area.
      </p>
    </div>))}

    {renderDiv(6, (
      <div>
      <p style={{fontSize:12}}>
Generally, Ready Mix Concrete is designed by an engineer which is generally known as concrete mix design of a specific grade viz M25, M30 etc... The design reports show the proportions of the various ingredients (coarse and fine aggregates, cement, water, often fly ash, admixture) of concrete based on the laboratory test mix for a particular grade.<br></br>
The slump test and compression test are two of the most common types of tests used for checking the quality of ready mix concrete. While a slump test is performed on-site, a compression test is performed in a lab. A supervisor performs the slump test before the concrete is about to be inserted into the formwork. However, if during the concrete laying process if the supervisor finds that the green concrete is getting dry or if the concrete placement is interrupted, the remaining concrete is tested again.<br></br>
The compression test is conducted for determining the characteristic strength of concrete. The result of this test is important for accepting in-site concrete work as it determines the concrete's strength. Historical slump and strength data are also taken into consideration. Moreover, for large projects trial mixes are often required.<br></br>
We ensure after exhaustive laboratory and plant trials are conducted, and honestly reported the ready mixed concrete mixes are supplied to ensure good quality.
      </p>
    </div>))}

    {renderDiv(7, (
      <div>
      <p style={{fontSize:12}}>
      Although sampling and test cube preparation are simple methods, the procedures are operator-sensitive; hence it is essential to employ trained personnel. Take for example, extraction of a “representative” sample. You know that correct procedure involves taking four incremental samples from the middle 80% of the load, ignoring the first and the last 10% portion coming out from the truck mixer and then thoroughly mixing the incremental samples. Unless workmen are trained in this operation, they tend to violate the correct procedures. Similarly, standard procedures for making cube test specimens (filling cubes in layers, tamping each layer 25 times, adequate protection and curing of test specimens, etc.) tend to get violated on many occasions. This is mainly because the workmen at site are not trained adequately to do these jobs. If sampling and cube preparation work are done incorrectly, test results could be erroneous and the producer may get punished for no fault his own. Therefore, it is our considered opinion that sampling and cube preparation should be done only by the trained and experienced personnel.
      </p>
    </div>))}

      {renderDiv(8, ( 
      <div>
      <p style={{fontSize:12}}>
      The concrete will begin to set once water is added at the site. For average, you will have to lay and level the ready mix concrete between 1 and 2 hours before the work ability is greatly reduced.
      </p>
    </div>))}

      {renderDiv(9, ( 
      <div>
      <p style={{fontSize:12}}>
      Long-term durability of your structures is dependent upon a variety of factors. Important amongst them are the exposure conditions to which the structure will be subjected to during service life, structural design and detailing, quality of concrete and precautions taken during placement, compaction and curing of concrete.<br></br><br></br>
NE RMC Readymix continuously strive to focus on quality services and hence seek feedback constantly and in nearly a decade, we are proud of being able to maintain a high customer satisfaction level of 97%.
      </p>
    </div>))}

      {renderDiv(10,(
      <div>
      <p style={{fontSize:12}}>

      </p>
    </div>))}

      {renderDiv(11, (
      <div>
      <p style={{fontSize:12}}>
      Sure. But as you know the successful mixing and transporting of concrete requires the careful management of many factors.You would therefore understand that our liability for concrete consistency (slump, density, and compressive strength) will only be true when we pour concrete into your vehicles.
      </p>
    </div>))}

      {renderDiv(12, (
      <div>
      <p style={{fontSize:12}}>
      Curing of concrete is the process of maintaining moisture inside the freshly casted concrete to continue the hydration process to achieve the desire properties of hardened concrete. Curing of concrete must begin as soon as possible after placement & finishing and must continue for a reasonable period of time as per the relevant standards, for the concrete to achieve its desired strength and durability. It is suggested that the curing process for concrete should commence immediately once the concrete surface looses its water and undergo shrinkage, which is normally within 2 hours after finishing compaction of concrete. Else, it will lead to plastic shrinkage cracks.
      </p>
    </div>))}

      {renderDiv(13, (
      <div>
      <p style={{fontSize:12}}>
      During delivery, the most common compliance conditions are slump value of concrete on site and concrete installation temperature, depending on the specifications and requirements of the client. Other than that, for compliance purposes, the quality of the concrete supplied will be judged on the basis of the compressive strength of concrete cube 28 days against the concrete grade ordered.
      </p>
    </div>))}

      {renderDiv(14, (
      <div>
      <p style={{fontSize:12}}>
      Fly ash benefits fresh concrete by reducing the mixing water requirement and improving the paste flow behaviour. The resulting benefits are as follows:<br></br><br></br>
* Improved workability. The spherical shaped particles of fly ash act as miniature ball bearings within the concrete mix, thus providing a lubricant effect. This same effect also improves concrete pumpability by reducing frictional losses during the pumping process and flat work finish ability.<br></br><br></br>
* Decreased water demand.The replacement of cement by fly ash reduces the water demand for a given slump. When fly ash is used at about 20 percent of the total cementitious, water demand is reduced by approximately 10 percent. Higher fly ash contents will yield higher water reductions. The decreased water demand has little or no effect on drying shrinkage/cracking. Some fly ash is known to reduce drying shrinkage in certain situations.<br></br><br></br>
* Reduced heat of hydration.Replacing cement with the same amount of fly ash can reduce the heat of hydration of concrete. This reduction in the heat of hydration does not sacrifice long-term strength gain or durability. The reduced heat of hydration lessens heat rise problems in mass concrete placements.
      </p>
    </div>))}

      {renderDiv(15, (
      <div>
      <p style={{fontSize:12}}>
      Ready-mix concrete is eco-friendly in a lot of ways. Concrete's ingredients (water, aggregate, and cement) are plentiful in supply and have a lesser impact on the environment in their extraction than other building materials. Quarries, the main source of raw materials, can be quickly reclaimed for residential, recreational and commercial use. They can also be refurbished to their natural condition. Plus, leftovers are readily reused or recycled.
      </p>
    </div>))}

      </div>

    </Row>
  )
}

export default Faq