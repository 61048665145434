import React from 'react'

import infrastructure from '../image/infrastructure.png'

const Infrastructure = () => {
  return (
    <div className="row" style={{margin:0,padding:0}}>
    <div className="col-lg-8 sm-12" style={{margin:0,padding:20}}>
          <p style={{fontSize:'40px',color:'#393185',lineHeight:'1'}}>Infrastructure</p>
          <p style={{fontSize:'12px', textAlign:'justify', color:'red', lineHeight:'.7'}}><b>Essential for every Infrastructure...</b></p>
         <p style={{fontSize:'12px', textAlign:'justify', color:'black'}}>NE RMC Readymix is proud of it Long acquired knowldege base by its team having expertise at Sales , Service, Operation and Quality Control had served at RMC organizations in different capacities for many years. We endeavor to deliver every customer needs with the best value proposition thus making our product essential for every infrastructure</p><br></br>
          
          <p style={{fontSize:'12px', textAlign:'justify', color:'red', lineHeight:'.7'}}><b>Our History...</b></p>
          <p style={{fontSize:'12px', textAlign:'justify', color:'black'}}>Established in March 2017 , the company employs more than 100+ people operating in various fields respectively and produces more than 1 million cubic metres of concrete every year.<br></br><br></br>
         For almost 6 years, we have been  successfully supplying concrete to the entire scale of project in the market : from residual to individual to buidlers to govt sectors prestigous technically demanding commercial and industrial projects. We have produced and poured Lakhs of cubic metres to date.<br></br><br></br>
          In the process we have acquired a wealth of industry experience unsurpassed in the region. Be it actual supplies or pre-concreting consultation, our vast experince will always guide us to select the right mix, the right set of equipment and the right placing method to ensure that the serviceable lifetime of concrete structure exceeds the durability expectation of the clients</p><br></br>
    </div>
    <div className="col-lg-4 sm-12" style={{margin:0,padding:30}}>
      <img src={infrastructure} alt=""style={{width:'100%'}}/>
    </div>
    <div className="col-lg-12 sm-12" style={{margin:0,paddingLeft:20, paddingRight:20}}>
          <p style={{fontSize:'40px',color:'#EF7F1A',lineHeight:'1'}}>Plant and Machineries</p>
          <p style={{fontSize:'12px', textAlign:'justify', color:'black'}}>We maintained our commercial plant with all the requisite machineries to cater our valuable customer and market demand for 24x7 hours. Our machineries include the following<br></br><br></br>
          <b style={{color:'red'}}>* </b><b>CP 30</b>, Schwing Steter Plant with Advance Skada Software for precise batching control<br></br><br></br>
          <b style={{color:'red'}}>* </b><b>60 MT Fly Ash Silo</b>and 100 MT cement silo, to keep adequate cement stock for Non-Stop pour for several days<br></br><br></br>
          <b style={{color:'red'}}>* </b><b>Fleet size of 11 numbers</b>of new transit mixtures of 7 CUM capcity, which gives additional teeth to carry volume to far long distance with less travel time thereby reducing the pour completion time.<br></br><br></br>
          <b style={{color:'red'}}>* </b><b>3 Numbers of Concrete Pump</b> of different capacity of Schwing make, 1200 D and 1400 D to pump concrete at different heights and distance along with two sets of pipeline of 100 meters each.<br></br><br></br>
          <b style={{color:'red'}}>* </b><b>Well setup QC Lab</b> having Compressive Testing Machine of 2000 KN load capacity of AMIL Make, Trial Mixer including Accelerated Cwig Tank and other necessary equipments required for day to day QC control activity and having requisite manpower for best QC practices at plant as well as during concerete pour executon. The QC team comprises of QC-Incharge, Lab-technician and site technician to control quality of raw material, and to control concrete<br></br><br></br>
          <b style={{color:'red'}}>* </b><b>Integrated Weigh Bridge System of 80 MT</b> capacity to take care of incoming raw material and outgoing concrete and to cross check the proper quantity dispatched to our valuable customer.
          </p>
 </div>
</div>
  )
}

export default Infrastructure