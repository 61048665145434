import React from 'react'

import nermctruck from '../image/nermctruck.jpg'
import truck from '../image/truck.gif'

const AboutUs = () => {
  return (
    <div className="row" style={{margin:0,padding:0}}>
      <div className="col-lg-8 sm-12" style={{margin:0,padding:20}}>
            <p style={{fontSize:'40px',color:'#393185',lineHeight:'1'}}>About Us</p>
            <p style={{fontSize:'12px', textAlign:'justify', color:'black', lineHeight:'.7'}}><b>Smart Choice for Smart Constructions</b></p>
            <p style={{fontSize:'12px', textAlign:'justify', color:'#5B5B5B'}}>NE RMC is a leading manufacturer and supplier of readymix concrete (RMC) catering the concrete requiremnts in and around Guwahati. We are synonymous to trust and quality and we believe to deliver products to build a smart, beautiful and progressive world around you. </p>
            <p style={{fontSize:'12px', textAlign:'justify', color:'black', lineHeight:'1'}}><b>Its not about What we Build, Its about Building New Positibilties...</b></p>
            <p style={{fontSize:'12px', textAlign:'justify', color:'#5B5B5B'}}>We have developed the most innovative range of ready-mix concrete solutions and services. Already trending as the favourite amongst the Individual House Builders, Architects and Contractors community NE Readymix is designed to support building and infrastructures in meeting challenges with North Eastern Indian Zone. As the rising leading of this realm, we have leveraged our high-level research capabilties to develop the quality, consistency and reliability of the product to satisfy our customer most stringent demands. We offer world class service, ontime deliveries and mix design mangaement to get the concrete you need for construction projects.</p>
      </div>
      <div className="col-lg-4 sm-12" style={{margin:0,padding:20}}>
            <img src={nermctruck} alt=""style={{width:'100%',}}/>
      </div>
      <div className="col-lg-5 sm-12" style={{margin:0,padding:10,zIndex:-2}}>
         <img src={truck} alt=""style={{width:'100%',marginTop:0}}/>
      </div>
      <div className="col-lg-7 sm-12" style={{margin:0,padding:20}}>
            <p style={{fontSize:'40px',color:'#EF7F1A',lineHeight:'1'}}>What is Ready-Mix concrete (RMC)</p>
            <p style={{fontSize:'12px', textAlign:'justify', color:'#5B5B5B'}}>RMC is ready to use concrete that is manufactured at a factory or batching plant, according to a set recipe (or as per the client specification) and then derived to the worksites by truck mounted mixers(transit mixer)<br></br><br></br>
          RMC is often preferred over on-site concrete mixing because of the precision and quality of the mix, reduction of onsite confusion and expenses and a high versatility in usage due to various placing methods<br></br><br></br>
          NE RMC conccrete ensures quality of concrete mix, by passing the concrete samples through various tests in a well equipped laboratory. We assure customer satisfaction by providing the finest quality, accurate quantity and uninterrupted delivery. </p>
   </div>
  </div>
  )
}

export default AboutUs