import React from 'react'
import  './style.css'
import Row from 'react-bootstrap/Row';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

import ongoing from '../image/ongoing.png'
import completed from '../image/completed.png'
import { Component } from 'react';

import aai from '../image/client/aai.png'
import hites from '../image/client/hites-logo.png'
import pwd from '../image/client/pwd.png'
import epil from '../image/client/epil.png'
import nrl from '../image/client/nrl.png'
import iocl from '../image/client/iocl.png'
import powergrid from '../image/client/powergrid.png'
import nfrailway from '../image/client/nfrailway.png'
import downtown from '../image/client/downtown.png'
import hetero from '../image/client/hetero.png'
import dalmia from '../image/client/dalmia.png'
import aseb from '../image/client/aseb.png'
import gmda from '../image/client/gmda.png'
import cpwd from '../image/client/cpwd.png'

class Projects extends Component{
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow : 6,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed : 2000,
      cssEase: "linear"
    };
  return (
    <div>
      <Row className="" style={{margin:0,padding:10}}>
      <div className="col-md-12 sm-12" style={{margin:0,padding:0, marginTop:20}}>
            <p style={{fontSize:40,lineHeight:.8, color:'#393185'}}>Our Prestigous Clients</p>
        </div>
        <div className="col-md-12 sm-12" style={{margin:0,padding:0,}}>
          <div className="row" style={{margin:0,padding:0}}>
            
           <Slider {...settings}>
              <div className="compview">
              <img src={aai} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
              <div className="compview">
              <img src={hites} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
              <div className="compview">
              <img src={pwd} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
              <div className="compview">
              <img src={epil} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
              <div className="compview">
              <img src={nrl} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
              <div className="compview">
              <img src={iocl} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
              <div className="compview">
              <img src={powergrid} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
              <div className="compview">
              <img src={nfrailway} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
              <div className="compview">
              <img src={downtown} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
              <div className="compview">
              <img src={hetero} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
              <div className="compview">
              <img src={dalmia} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
              <div className="compview">
              <img src={aseb} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
              <div className="compview">
              <img src={gmda} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
              <div className="compview">
              <img src={cpwd} alt=""style={{width:'100%',marginTop:0}}/>
              </div>
           </Slider>
           </div>
        </div>
        <div className="col-md-6 sm-12" style={{margin:0,padding:0,marginTop:20}}>
        <p style={{fontSize:'40px',color:'#393185',lineHeight:'1'}}>Our Projects</p>
        </div>
        <div className="col-md-3 sm-12" style={{margin:0,padding:0, marginTop:20}}>
          <div className="compview" style={{margin:0,padding:0}}>
            <div className="col-md-1 sm-2" style={{margin:0,padding:0}}>
            <img src={ongoing} alt=""style={{width:'90%',marginTop:0}}/>
            </div>
            <div className="col-md-2 sm-4" style={{margin:0,padding:0}}>
            <p style={{fontSize:'12px',color:'#393185',lineHeight:'1'}}>Ongoing Project</p>
            </div>
            <div className="col-md-2 sm-4" style={{margin:0,padding:0}}>
            </div>
            <div className="col-md-1 sm-2" style={{margin:0,padding:0}}>
            <img src={completed} alt=""style={{width:'90%',marginTop:0}}/>
            </div>
            <div className="col-md-2 sm-4" style={{margin:0,padding:0}}>
            <p style={{fontSize:'12px',color:'#393185',lineHeight:'1'}}>Completed Project</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 sm-12" style={{margin:0,padding:0, marginTop:20}}></div>

        <div className="col-md-3 sm-12" style={{margin:0,padding:0,}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={ongoing} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#393185'}}>New Terminal Building, Guwahati Airport</b><br></br>
                Contactor : Shapporji Pallonji Pvt Ltd<br></br>
                Qty Supplied : 110000 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={ongoing} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#393185'}}>
                AIIMS Guwahati</b><br></br>
                Contactor : NCC<br></br>
                Qty Supplied : 4000 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={ongoing} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#393185'}}>
                  State Convention Center</b><br></br>
                Contactor : Shapoorji Pallonji<br></br>
                Qty Supplied : 3000 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={ongoing} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#393185'}}>
                  Tata Memorial Cancer Hospital</b><br></br>
                Contactor : L&T<br></br>
                Qty Supplied : 1000 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={ongoing} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#393185'}}>
                  N.I.P.E.R Institute</b><br></br>
                Contactor : NCC Ltd<br></br>
                Qty Supplied : 10000 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={completed} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#B72221'}}>
                  NRL Headquater</b><br></br>
                Contactor : S.M. Khetawat<br></br>
                Qty Supplied : 9000 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={ongoing} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#393185'}}>
                  Guwahati Airport Runway Work</b><br></br>
                Contactor : Vishal Infrastructure Pvt Ltd<br></br>
                Qty Supplied : 1000 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={ongoing} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#393185'}}>
                  IOCL Extension Noonmati</b><br></br>
                Contactor : G.B. Construction and others<br></br>
                Qty Supplied : 2000 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={completed} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#B72221'}}>
                  PowerGrid Office Building</b><br></br>
                Contactor : Buildrite Construction<br></br>
                Qty Supplied : 1400 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={completed} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#B72221'}}>
                  Railway Double Track Project</b><br></br>
                Contactor : Nayak Infrastructure Pvt Ltd<br></br>
                Qty Supplied : 800 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={completed} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#B72221'}}>
                Railway Staff Quarter</b><br></br>
                Contactor : Tapan deka / Narayan Dekar<br></br>
                Qty Supplied : 11000 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={ongoing} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#393185'}}>
                  N.F. Railway Headquarter</b><br></br>
                Contactor : Shass Engineers<br></br>
                Qty Supplied : 2000 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={ongoing} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#393185'}}>
                  Hostel & Admin Block-Downtown University</b><br></br>
                Contactor : Self<br></br>
                Qty Supplied : 5000 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={completed} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#B72221'}}>
                 Hetero Labs Industry</b><br></br>
                Contactor : Self<br></br>
                Qty Supplied : 1000 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={completed} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#B72221'}}>
                  Dalmia Cement New Grinding Unit</b><br></br>
                Contactor : Buildmate Pvt Ltd<br></br>
                Qty Supplied : 3500 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={completed} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#B72221'}}>
                  Hetero Drugs Industry</b><br></br>
                Contactor : Self<br></br>
                Qty Supplied : 500 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={completed} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#B72221'}}>
                  PWD Bridge Bagharbari, ISBT</b><br></br>
                Contactor : NE Plantation Pvt Ltd<br></br>
                Qty Supplied : 1200 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={completed} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#B72221'}}>
                  Power Sub Station</b><br></br>
                Contactor : NC Power Pvt Ltd<br></br>
                Qty Supplied : 800 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={completed} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#B72221'}}>
                  National Law University Quarter Building</b><br></br>
                Contactor : NC Hatimoria<br></br>
                Qty Supplied : 800 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={ongoing} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#393185'}}>
                  Assam MLA Hostel Complex</b><br></br>
                Contactor : NC Hatimoria<br></br>
                Qty Supplied : 500 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={ongoing} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#393185'}}>
                  Jika Water Supply Projejct</b><br></br>
                Contractor : JITF Aquasource Jindal ITF<br></br>
                Qty Supplied : 1000 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={completed} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#B72221'}}>
                  Saraighat Bridge Deck Slab Work</b><br></br>
                Contactor : I J Construction<br></br>
                Qty Supplied : 800 CUM</p>
              </div>
            </div>
        </div>
        
        <div className="col-md-3 sm-12" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-2" style={{margin:0,padding:0}}>
                <img src={ongoing} alt=""style={{width:'90%',marginTop:0}}/>
              </div>
              <div className="col-10" style={{margin:0,padding:0}}>
                <p style={{fontSize:12,lineHeight:1.2}}><b style={{color:'#393185'}}>
                 IASST Boragaon </b><br></br>
                Contractor : Bimal Kr Agarwala<br></br>
                Qty Supplied : 500 CUM</p>
              </div>
            </div>
        </div>
      </Row>
      <Row className="mobileview" style={{margin:0,padding:0}}>
        
      </Row>
    </div>
  );
  }
}

export default Projects