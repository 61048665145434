import React,{useState} from 'react';
import  './style.css'
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import HoverImage from "react-hover-image";


import logo from '../image/logo.png'
import nermc from '../image/nermc.png'
import concretelogo from '../image/concretelogo.png'
import citycomp from '../image/citycomp.png'
import citymobile from '../image/citymobile.png'
import smartchoice from '../image/smartchoice.png'
import calculator from '../image/calculator1.png'
import calculatorhover from '../image/calculator2.png'
import facebook from '../image/icons/facebook.png'
import instagram from '../image/icons/instagram.png'
import linkedin from '../image/icons/linkedin.png'
import twitter from '../image/icons/twitter.png'
import home from '../image/icons/home.png'
import facebookhover from '../image/icons/facebookhover.png'
import instagramhover from '../image/icons/instagramhover.png'
import linkedinhover from '../image/icons/linkedinhover.png'
import twitterhover from '../image/icons/twitterhover.png'
import homehover from '../image/icons/homehover.png'
import animation1 from '../image/2.gif'
import animation2 from '../image/1.gif'
import menu from '../image/icons/menu.png'
import whatsapp from '../image/icons/whatsapp.png'
import whatsapphover from '../image/icons/whatsapphover.png'

const Header = () => {
  const [isFocusedMenu, setIsFocusedMenu] = useState(false);
  const [zIndex, setZIndex] = useState(-1);

  const handleInputFocusMenu = () => {
    setIsFocusedMenu(true);
    setZIndex(55);
  }

  const handleInputBlurMenu = () => {
    setIsFocusedMenu(false);
    setZIndex(1);
  }

  return (
    <header>
      <Row className="compview" style={{margin:0,padding:0}}>
        <div className="row" style={{margin:0,padding:10,paddingBottom:3}}>
          <div className="col-md-1" style={{margin:0,padding:0}}>
          <img src={logo} alt=""style={{width:'100%',}}/>
          </div>

          <div className="col-md-9" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-md-11" style={{margin:0,padding:0}}>
                <div className="row" style={{margin:0,padding:0}}>
                  <div className="col-md-3" style={{margin:0,padding:0,marginLeft:15}}>
                    <img src={nermc} alt=""style={{width:'100%',}}/>
                  </div>
                  <div className="col-md-3" style={{margin:0,padding:0,marginLeft:20}}>
                      <p style={{fontSize:11,lineHeight:'10px'}}>Pamohi, Guwahati, Assam 781035<br></br>Call : +91 7577015203 / 7577015202<br></br>Email : info@nermc.co.in</p>
                  </div>
                  <div className="col-md-3" style={{margin:0,padding:0,marginLeft:-20}}>
                  <Link className="nav-link" to="/calculator" ><HoverImage style={{width:'90%',paddingRight:20}} src={calculator} hoverSrc={calculatorhover}/></Link>
                  </div>
                  <div className="col-md-3" style={{margin:0,padding:0,marginLeft:-35}}>
                      <div className="row" style={{margin:0,padding:0}}>
                        <div className="col-md-2" style={{margin:0,padding:0,marginTop:-5}}>
                          <a href="https://www.facebook.com/profile.php?id=100063725030725"><HoverImage style={{width:'100%',padding:5}} src={facebook} hoverSrc={facebookhover}/></a>
                        </div>
                        <div className="col-md-2" style={{margin:0,padding:0,marginTop:-5}}>
                        <a href="https://instagram.com"><HoverImage style={{width:'100%',padding:5}} src={instagram} hoverSrc={instagramhover}/></a>
                        </div>
                        <div className="col-md-2" style={{margin:0,padding:0,marginTop:-5}}>
                        <a href="https://linkedin.com"><HoverImage style={{width:'100%',padding:5}} src={linkedin} hoverSrc={linkedinhover}/></a>
                        </div>
                        <div className="col-md-2" style={{margin:0,padding:0,marginTop:-5}}>
                        <a href="https://twitter.com"><HoverImage style={{width:'100%',padding:5}} src={twitter} hoverSrc={twitterhover}/></a>
                        </div>
                        <div className="col-md-2" style={{margin:0,padding:0,marginTop:-5}}>
                       <a href="https://api.whatsapp.com/send?phone=8486444152"><HoverImage style={{width:'100%',padding:5}} src={whatsapp} hoverSrc={whatsapphover}/></a><br></br>
                        </div>
                        <div className="col-md-2" style={{margin:0,padding:0,}}>
                        <Link className="nav-link" to="/" ><HoverImage style={{width:'100%'}} src={homehover} hoverSrc={home}/></Link>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="row" style={{margin:0,padding:0}}>
                  <img src={citycomp} alt=""style={{width:'100%',}}/>
                </div>
              </div>
              <div className="col-md-1" style={{margin:0,padding:0,marginTop:-15}}>
              <img src={concretelogo} alt=""style={{width:'100%',padding:10}}/>
              </div>
            </div>
            <div className="row" style={{margin:0,padding:0,marginTop:-12,marginLeft:15}}>
              <nav className="navbar navbar-expand-lg" style={{width:'100%'}}>
                <Link className="nav-link" to="/aboutus" style={{color:'#393185',fontSize:12.2}}>ABOUT US</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                <Link className="nav-link" to="/infrastructure" style={{color:'#393185',fontSize:12.2}}>INFRASTRUCTURE</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                <Link className="nav-link" to="/vision" style={{color:'#393185',fontSize:12.2}}>VISION AND GOALS</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                <Link className="nav-link" to="/research" style={{color:'#393185',fontSize:12.2}}>RESEARCH AND DEVELOPMENT</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                <Link className="nav-link" to="/team" style={{color:'#393185',fontSize:12.2}}>DIRECTORS & TEAM</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                <Link className="nav-link" to="/projects" style={{color:'#393185',fontSize:12.2}}>OUR PROJECTS</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                <Link className="nav-link" to="/faq" style={{color:'#393185',fontSize:12.2}}>FAQ</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                <Link className="nav-link" to="/download" style={{color:'#393185',fontSize:12.2}}>DOWNLOADS</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                <Link className="nav-link" to="/contact" style={{color:'#393185',fontSize:12.2}}>CONTACT US</Link>
              </nav>
            </div>
          </div>

          <div className="col-md-2" style={{margin:0,padding:0}}>
            <div className="row" style={{margin:0,padding:0}}>
              <div className="col-md-6" style={{margin:0,padding:0,zIndex:11}}>
                 <img src={smartchoice} alt=""style={{width:'100%',}}/>
              </div>
              <div className="col-md-6" style={{margin:0,padding:0,zIndex:11}}>
                <img src={animation1} alt=""style={{width:'100%',}}/>
              </div>
            </div>
            <div className="row" style={{margin:0,padding:0,zIndex:-2,marginTop:-70,marginRight:70}}>
             <img src={animation2} alt=""style={{width:'100%',}}/>
            </div>
          </div>
        </div>
      </Row>

      <Row className="mobileview">
      <div className={isFocusedMenu ? 'menu clicked' : 'menu'} style={{zIndex: zIndex, position:'fixed',padding: 0, top: 0,borderRadius: 5, backgroundColor: 'grey' , width:200,height: 'auto' }}>
            <div className="row" style={{zIndex: zIndex,marginTop:20,marginLeft:20,padding:0,height:40}}>
            <Link className="nav-link" to="/" style={{color: 'white'}}>HOME</Link><br></br>
            </div>
            <div className="row" style={{zIndex: zIndex,marginLeft:20,padding:0,height:40}}>
            <Link className="nav-link" to="/aboutus" style={{color: 'white'}}>ABOUT US</Link><br></br>
            </div>
            <div className="row" style={{zIndex: zIndex,marginLeft:20,padding:0,height:40}}>
            <Link className="nav-link" to="/infrastructure" style={{color: 'white' }}>INFRASTRUCTURE</Link><br></br>
            </div>
            <div className="row" style={{zIndex: zIndex,marginLeft:20,padding:0,height:40}}>
            <Link className="nav-link" to="/vision" style={{color: 'white'}}>VISION AND GOALS</Link><br></br>
            </div>
            <div className="row" style={{zIndex: zIndex,marginLeft:20,padding:0,height:40}}>
            <Link className="nav-link"  to="/research" style={{color: 'white'}}>RESEARCH & DEV</Link><br></br>
            </div>
            <div className="row" style={{zIndex: zIndex,marginLeft:20,padding:0,height:40}}>
            <Link  className="nav-link" to="/team" style={{color: 'white' }}>DIRECTORS AND TEAM</Link><br></br>
            </div>
            <div className="row" style={{zIndex: zIndex,marginLeft:20,padding:0,height:40}}>
            <Link className="nav-link" to="/projects" style={{color: 'white' }}>OUR PROJECTS</Link><br></br>
            </div>
            <div className="row" style={{zIndex: zIndex,marginLeft:20,padding:0,height:40}}>
            <Link  className="nav-link" to="/faq" style={{color: 'white'}}>FAQ</Link><br></br>
            </div>
            <div className="row" style={{zIndex: zIndex,marginLeft:20,padding:0,height:40}}>
            <Link  className="nav-link" to="/download" style={{color: 'white'}}>DOWNLOADS</Link><br></br>
            </div>
            <div className="row" style={{zIndex: zIndex,marginLeft:20,padding:0,height:40}}>
            <Link  className="nav-link" to="/calculator" style={{color: 'white'}}>RMC CALCULATOR</Link><br></br>
            </div>
            <div className="row" style={{zIndex: zIndex,marginLeft:20,padding:0,height:40}}>
            <Link  className="nav-link" to="/contact" style={{color: 'white' }}>CONTACT US</Link>
            </div>
            <div className="" style={{ bottom:0,textAlign:'right',}}><br></br>
              <button onMouseEnter={handleInputBlurMenu}
              style={{backgroundColor:'black',textDecoration:'none',width:'100%',border:'none',padding:0,margin:0}}>
              <p style={{marginRight:20,color:'white',fontSize:12}}><br></br>X   CLOSE</p>
              </button>
            </div>
          </div>

            <div className="col-3" style={{padding:5,margin:0,marginLeft:15}}>
              <img src={logo} alt=""style={{width:'90%',}}/>
            </div>
            <div className="col-9" style={{margin:0,padding:0,marginLeft:-15}}>
              <div className="row" style={{margin:0,padding:0}}>
                <div className="col-7" style={{margin:0,padding:5}}>
                  <img src={nermc} alt=""style={{width:'100%',}}/>
                </div>
                <div className="col-2" style={{margin:0,padding:5}}>
                <img src={concretelogo} alt=""style={{width:'100%'}}/>
                </div>
                <div className="col-3" style={{margin:0,padding:15,marginLeft:-12,marginTop:-10}}>
                <button onMouseEnter={handleInputFocusMenu} onMouseLeave={handleInputBlurMenu}
                style={{color:'black',textDecoration:'none',border:'none',padding:0,margin:0}}>
                <img src={menu} alt=""style={{width:'100%'}}/>
                </button>
                </div>
              </div>
              <div className="row" style={{margin:0,padding:0,marginTop:-30,zIndex:-2}}>
                <div className="col-12" style={{margin:0,padding:0}}>
                <img src={citymobile} alt=""style={{width:'100%',}}/>
                </div>
              </div>
            </div>
           
      </Row>
      </header>
  )
}

export default Header