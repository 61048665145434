import React from 'react'

const Footer = () => {
  return (
    <div className="compview" style={{margin:0,padding:0,backgroundColor:'#CD2628'}}>
      <div className="row" style={{margin:0,padding:0}}>
        <div className="col-md-2" style={{margin:0,padding:10, color:'white'}}>
            <p style={{fontSize:9}}><b style={{fontSize:13}}>copyright@NE RMC Readymix</b><br></br>
            Design by Jeevisha Marketing, 8011007300</p>
        </div>
        <div className="col-md-10" style={{margin:0,padding:10,color:'white'}}>
          <p style={{fontSize:8.5}}>Disclaimer : The information contained in this website is for general information purposes only. The information is provided by NE RMC and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. </p>
        </div>
      </div>
    </div>
  )
}

export default Footer