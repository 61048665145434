import React,{useState,useEffect} from 'react';
import { useAlert } from 'react-alert';
import axios from "axios";
import Iframe from 'react-iframe'

const ContactUs = () => {
  const [name,setName] = useState('');
  const [mobile,setMobile] = useState('');
  const [email,setEmail] = useState('');
  const [message,setMessage] = useState('');
  const alert = useAlert();

  const postData = (e) => {
    e.preventDefault();

    const data = {
      name: name,
      mobile: mobile,
      email: email,
      message: message
    };

    axios.post(`https://nermc.co.in/send-email`, data)
    .then((res) => {
      alert.success('Thanks for contacting us. Our representative will contact you soon.');
    })
    .catch((err) => console.log(err));
};

useEffect(() => {
  // Remove the alert after 5 seconds
  const timeout = setTimeout(() => {
    alert.remove();
  }, 2000);

  // Clean up the timeout when the component unmounts
  return () => {
    clearTimeout(timeout);
  };
}, [alert]);

  
  return (
    <div className="contact">
      <div className="row" style={{ padding: 40, marginTop: -20 }}>
        <div
          className="col-md-7"
          style={{ padding: 20, borderRadius: 5, backgroundColor: '#B2B3B3' }}
        >
          <h1 style={{ color: '#393185', fontWeight: 'lighter' }}>
            Feel Free to Contact Us
          </h1>
          <form>
            <input
              className="form-control mr-sm-2"
              style={{ padding: 5 }}
              type="name" 
              name="name" 
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <br />
            <input
              className="form-control mr-sm-2"
              style={{ padding: 5 }}
              type="text"
              placeholder="Mobile Number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
            <br />
            <input
              className="form-control mr-sm-2"
              style={{ padding: 5 }}
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <input
              className="form-control mr-sm-2"
              style={{ padding: 20 }}
              type="text"
              placeholder="Details of your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <br />
            <button
              style={{
                padding: 5,
                backgroundColor: '#393185',
                width: '100%',
                borderRadius: 10,
                textDecoration: 'none',
                border: 'none',
                color: 'white',
              }}
              type="submit"
              onClick={postData}
            >
              SUBMIT
            </button>
          </form>
        </div>
      <div className="col-md-5" style={{padding:20, textAlign:'center'}}>
        <div className="row" style={{borderBottom:'1px solid grey', textAlign:'center'}}>
          <p style={{color:'#393185'}}><b style={{color:'black'}}>Call us for Queries :</b><br></br>
          Helpdesk: +91 7577015203 / 7577015202<br></br>
          Whatsapp : <a href="https://api.whatsapp.com/send?phone=8486444152">+91 8486444152</a><br></br>
          (Mon-Sat:10:00AM-6:00PM)</p>
        </div>
        <div className="row" style={{borderBottom:'1px solid grey', textAlign:'center'}}><br></br>
        <p style={{color:'#393185'}}><br></br><b style={{color:'black'}}>Email us</b><br></br>
          Sales enquiries and customer support<br></br>
          sales@nermc.co.in</p>
        </div>
        <div className="row" style={{textAlign:'center'}}>
        <p style={{color:'#393185'}}><br></br><b style={{color:'black'}}>Postal Address : </b><br></br>
            NE RMC Readymix<br></br>
            Address : Pamohi, Guwahati, Assam 781035</p>
        </div>
      </div>
    </div>
    <div className="row">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14331.413817309427!2d91.6926442!3d26.103702!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5cb8adc44e19%3A0x30ee8daf4abe7318!2sNE%20RMC%20READYMIX!5e0!3m2!1sen!2sin!4v1685183013372!5m2!1sen!2sin"
       height="600" style={{width:'100%',border:0}} allowfullscreen="" loading="lazy" ></iframe>
    </div>
    </div>
  )
}

export default ContactUs